<template>
  <div>
    <banner :list="list"></banner>

    <div v-if="refreshList">
      <list
        :url="producturl"
        :filterFunc="instrumentMap"
        :show="true"
      >
        <template v-slot:default="data">
          <yiqi
            :list="data"
            name="instrumentdetail"
            tid="4"
          ></yiqi>
        </template>
      </list>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  nextTick,
  computed,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
// import productCard from "@/components/common/productCard.vue";
import yiqi from "@/components/common/yiqiItem.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
//  utils
import api from "@/api/axios";
//ts
import { instrumentMap} from "@/hooks/useProduct";

export default defineComponent({
  name: "instrument",
  components: {
    banner,
    list,
    // productCard,
    yiqi,
  },
  setup() {
    const list = ref(null);
    const productList = ref(null);
    const route = useRoute();
    const type = ref(route.query.type || "1");
    const id = ref(route.query.id || "1");
    const router = useRouter();
    const producturl = computed(() => {
      return `/M/Server/GetInstrumentList`;
    });
    const refreshList = ref(true);
    api.get("/M/Product/instrument").then((res): void => {
      //图片
      list.value = res.data.obj.listimg;
    });
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.name;
        },
      ],
      (to, from) => {

          // 从分类跳转 或者是从首页的 标准物质跳转 会从新请求数据渲染 ，
          // 侧边栏的链接跳转直接复用之前的数据
      //   if (from[1] == "ProductCategory") {
      //     refreshList.value = false;
      //     type.value = String(route.query.type);
      //     id.value = String(route.query.id);
      //     nextTick(() => {
      //       refreshList.value = true;
      //     });
      //   } else if (from[1] == "Index") {
      //     refreshList.value = false;
      //     type.value = "1";
      //     id.value = "1";
      //     nextTick(() => {
      //       refreshList.value = true;
      //     });
      //   }
      }
    );

    //  切换列表菜单

    return {
      list,
      productList,
      producturl,
      instrumentMap,
      refreshList,
    };
  },
});
</script>

<style lang="scss" scoped>
//  list  nav
.menu-nav-wapper {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  border-bottom: 1.012px solid #f4f4f4;

  .menu-nav {
    overflow: hidden;
    height: 45px;
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid #f4f4f4;
    top: 0;
    z-index: 20;
    background-color: #fff;
  }
  li {
    float: left;
    width: 33.3%;
    text-align: center;
    a {
      display: block;
      text-align: center;
      line-height: 45px;
      width: 100%;
      font-size: 16px;
      color: #666;
    }
  }

  .active a {
    color: #b81462;
  }
}
</style>
